import React from "react"
import { SEO } from "components/SEO"
import SignedOutWrapper from "app/SignedOutWrapper"
import { ActivationForm } from "app/ActivationForm"

const Activate: React.FC = ({ ...props }) => {
  return (
    <SignedOutWrapper>
      <SEO title="Aktivér konto" slug="/" />
      <ActivationForm />
    </SignedOutWrapper>
  )
}

export default Activate
