import React, { useState, useContext, useEffect, useMemo } from "react"
import { Link, navigate } from "gatsby"
import { useParams } from "@gatsbyjs/reach-router"
import styled from "styled-components"
import "twin.macro"
import Cookies from "js-cookie"
import Checkbox from "lib/Checkbox"
import {
  Button,
  ButtonGroup,
  Responsive,
  Form,
  FormField,
  ValidationInput,
  Loading,
  FormContext,
} from "@clevertrack/shared"
import { authenticate, isLoggedIn } from "services/auth"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import emailValidation from "utils/emailValidation"
import cogoToast from "@clevertrackdk/cogo-toast"
import uniq from "lodash-es/uniq"
import { UserContext } from "app/User/context"
import { UserActions, UserActionTypes } from "app/User/actions"
import { useFirestoreUser } from "services/firestore/user"
import { useSearchParam } from "react-use"
import {
  FormActions,
  FormTypes,
} from "@clevertrack/shared/src/components/Form/actions"
import { FormProvider } from "@clevertrack/shared/src/components/Form/context"
import { updateUserPassword } from "services/users"
import { UserTypes } from "app/User/types"

const StyledLogin = styled.div`
  position: relative;
  width: 80vw;

  ${(props) => props.theme.media.tablet_portrait_up`
    max-width: 60rem;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 2rem auto 0;
    max-width: 75%;
  `}

  ${(props) => props.theme.media.desktop_up`
    max-width: 55%;
  `}
`

const CheckboxField = styled.div`
  margin-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const StyledSysMsg = styled.small`
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: 400;
  font-size: 75%;
  ${(props) => props.theme.media.tablet_portrait_up`
    float: right;
  `}
`

export const ActivationForm: React.FC = ({ children, ...props }) => {
  const [loading, setLoading] = useState(false)
  const userID = useSearchParam("userID")
  const token = useSearchParam("token")
  const [password, setPassword] = useState("")
  const [passwordRepeat, setPasswordRepeat] = useState("")
  const [user, setUser] = useState<UserTypes | null>(null)
  const { dispatch } = useContext(UserContext)
  const { getFirebaseUser } = useFirestoreUser()

  useMemo(async () => {
    if (userID) {
      const u = await getFirebaseUser(userID)
      setUser(u)
    }
  }, [userID])

  async function onActivate(e) {
    e.preventDefault()
    setLoading(true)
    try {
      if (user && token === user.validationToken) {
        const res = await updateUserPassword({ token, password }, userID)
        if (res.result === "OK") {
          cogoToast.success("Dit password er blevet gemt. Logger ind...")
          const loginResult = await authenticate(user.email, password, false, 0)
          navigate("/app")
        }
      } else {
        cogoToast.error(
          "Kunne ikke aktivere konto. Kontakt venligst Clevertrack."
        )
      }
    } catch (error) {
      setLoading(false)
      // Otherwise try with Email/password login
      cogoToast.error("Forkert brugernavn eller password")
    }
  }

  const renderLoginLoader = () => {
    return (
      <Loading loadingText={`${!user ? "Finder din konto" : "Aktiverer..."}`} />
    )
  }

  const onValidatePasswordRepeat = (input) => {
    return input === password
  }

  return loading || !user ? (
    renderLoginLoader()
  ) : (
    <StyledLogin {...props}>
      <h2 tw="text-center mt-0">
        <span tw="text-4xl block">Hej {user.firstName}!</span>
        <span tw="text-3xl">Aktivér din konto</span>
      </h2>
      <Form onSubmit={onActivate}>
        <FormField validationKey="email" label="Email">
          <ValidationInput
            required
            id="email"
            type="email"
            placeholder="Indtast email"
            defaultValue={user.email}
            disabled
          />
        </FormField>
        <FormField validationKey="password" label="Password">
          <ValidationInput
            id="password"
            type="password"
            placeholder="Indtast password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormField>
        <FormField validationKey="passwordRepeat" label="Gentag password">
          <ValidationInput
            id="passwordRepeat"
            type="password"
            placeholder="Gentag password"
            value={passwordRepeat}
            customValidation={onValidatePasswordRepeat}
            customValidationMessages={{
              customError: "De indtastede passwords matcher ikke.",
            }}
            onChange={(e) => setPasswordRepeat(e.target.value)}
          />
        </FormField>
        <ButtonGroup direction="column">
          <Button
            type="submit"
            variant="primary"
            disabled={!password || password !== passwordRepeat}
          >
            Aktivér min konto
          </Button>
        </ButtonGroup>
      </Form>
    </StyledLogin>
  )
}
